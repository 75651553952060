<template>
  <div>
    <div class="page-title">
      <h3>Импорт заявок</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Вернуться'"
           @click="$router.push('/tasks')">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <div class="row">
      <blockquote>
        <p>
          Импорт заявок производится посредством загрузки CSV файла в кодировке UTF-8 с использованием символа
          <strong>;</strong> в качестве разделителя.
        </p>
        <p>Название отдела и <a href="/projects" target="_blank">проекта</a> должны в точности совпадать с тем, что
          заведено в справочниках CRM.
        </p>
        <p>
          Тип заявки может быть только <strong>ППР</strong> или <strong>КТО</strong>.
        </p>
        <p>
          Для указания инженера напишите его имя и фамилию так, как он заведен разделе
          "<a href="/users" target="_blank">Пользователи</a>".
        </p>
        <p>
          Внутреннее плановое время завершения указывается в формате <strong>ДЕНЬ.МЕСЯЦ.ГОД</strong>.<br/>
          Если месяц или день находится в интервале от 0 до 9, то <strong>обязательно</strong>
          впереди должна быть указана цифра ноль.<br/>
          Например 10 января 2021 года будет записано как <strong>10.01.2021</strong>.
        </p>
        <p>
          Внешний идентификатор объекта, отдел, тип, название, описание, внутреннее плановое время завершения и инженер
          являются обязательными для заполнения полями.
        </p>
        <p>
          Вы можете посмотреть пример использования в <a href="https://api.gsi62.ru/templates/tasks_import.csv">шаблоне</a>.
        </p>
      </blockquote>
    </div>

    <form class="form" ref="uploadForm" @submit.prevent="submitHandler">
      <div class="row">
        <div class="file-field input-field">
          <div class="btn blue darken-4">
            <span>Файл</span>
            <input type="file" v-on:change="handleFileUpload"/>
          </div>
          <div class="file-path-wrapper">
            <input class="file-path validate" type="text""/>
          </div>
        </div>
      </div>

      <div class="row">
        <button class="btn waves-effect waves-light blue darken-4" type="submit">
          Загрузить
          <i class="material-icons right">send</i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators'

export default {
  metaInfo() {
    return {
      title: 'Редактирование заявки'
    }
  },
  data: () => ({
    file: '',
  }),
  validations: {
    file_path: {required},
  },
  async mounted() {
  },
  methods: {
    async handleFileUpload(e) {
      let files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        return;
      }

      this.file = files[0];
    },
    async submitHandler() {
      if (!this.file) {
        return
      }

      await this.$store.dispatch('uploadTasks', this.file)
      await this.$router.push('/tasks')

      this.file = null;
      this.$refs.uploadForm.reset();
    },
  },
  destroyed() {
  }
}
</script>

<style scoped>
.switch {
  margin-top: 1rem;
}
</style>
